(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/normalize-jackpot-helper.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/normalize-jackpot-helper.js');
"use strict";

const _excluded = ["jackpots", "guaranteedJackpots"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }

const normalizeJackpot = (jackpots, draw) => {
  const drawJackpots = jackpots && jackpots.length > 0 ? jackpots.filter(jackpot => jackpot.drawNumber === draw.drawNumber).map(jackpot => {
    const {
        jackpots,
        guaranteedJackpots
      } = jackpot,
      cleanedJackpot = _objectWithoutProperties(jackpot, _excluded);
    const jackpotField = jackpots !== null && jackpots !== void 0 && jackpots.length ? jackpots.shift() : undefined;
    const guaranteedJackpotField = guaranteedJackpots !== null && guaranteedJackpots !== void 0 && guaranteedJackpots.length ? guaranteedJackpots.pop() : undefined;
    const exportedFields = {};
    if (jackpotField || guaranteedJackpotField) {
      exportedFields.description = (jackpotField === null || jackpotField === void 0 ? void 0 : jackpotField.description) || (guaranteedJackpotField === null || guaranteedJackpotField === void 0 ? void 0 : guaranteedJackpotField.description);
      exportedFields.winset = (jackpotField === null || jackpotField === void 0 ? void 0 : jackpotField.winset) || (guaranteedJackpotField === null || guaranteedJackpotField === void 0 ? void 0 : guaranteedJackpotField.winset);
      exportedFields.windiv = (jackpotField === null || jackpotField === void 0 ? void 0 : jackpotField.windiv) || (guaranteedJackpotField === null || guaranteedJackpotField === void 0 ? void 0 : guaranteedJackpotField.windiv);
      exportedFields.guaranteedJackpotAmount = guaranteedJackpotField === null || guaranteedJackpotField === void 0 ? void 0 : guaranteedJackpotField.jackpotAmount;
      exportedFields.jackpotAmount = (jackpotField === null || jackpotField === void 0 ? void 0 : jackpotField.jackpotAmount) || (guaranteedJackpotField === null || guaranteedJackpotField === void 0 ? void 0 : guaranteedJackpotField.jackpotAmount);
      exportedFields.defaultJackpot = Boolean(guaranteedJackpotField);
    }
    return _objectSpread(_objectSpread({}, cleanedJackpot), exportedFields);
  }) : [];
  return drawJackpots;
};
if (svs.isServer) {
  module.exports = {
    normalizeJackpot
  };
} else {
  setGlobal('svs.components.sport.drawsRedux.normalizeJackpot', normalizeJackpot);
}

 })(window);